//------------------------------------ Default / NPM package imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//------------------------------------ Util imports
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import EyeSlashIcon from "@heroicons/react/24/solid/EyeSlashIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";

//------------------------------------ API imports
import { EmailValidation, PasswordValidation } from "../../app/Validation";
import { CreateNewPassword } from "../../api/commonApi";
import ErrorText from "../../components/Typography/ErrorText";
import { showNotification } from "../common/headerSlice";

export const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const [passwordStrength, setpasswordStrength] = useState();
  const [password, setPassword] = useState();
  const [password1, setPassword1] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [NewPassword, setNewPassword] = useState({});
  const [NewPasswordValidation, setNewPasswordValidation] = useState({});

  const viewPassword = () => {
    setPassword(!password);
  };

  const viewPassword1 = () => {
    setPassword1(!password1);
  };

  const setNewPasswordValue = (key, value) => {
    setNewPassword({ ...NewPassword, [key]: value });
    if (NewPasswordValidation[key]) delete NewPasswordValidation[key];
  };

  const setNewPasswordValidationValue = (key, value) => {
    setNewPasswordValidation({ ...NewPasswordValidation, [key]: value });
  };
  const handleSubmit = async (e) => {
    setErrorMessage("");
    e.preventDefault();
    let validate = {};
    validate.password = PasswordValidation(NewPassword?.password);
    validate.re_password = PasswordValidation(NewPassword?.password);

    console.log(NewPassword);
    if (NewPassword?.password !== NewPassword?.re_password) {
      setErrorMessage("Both password does not match");
      return;
    }
    setNewPasswordValidation(validate);
    console.log(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        mobileNumber: localStorage.getItem("email"),
        newPassword: NewPassword.password,
      };
      console.log("validate", validate);
      const response = await CreateNewPassword(Payload);
      if (response && response.statusCode === 200) {
        // ShowToastSuccessMsg(response.message);
        dispatch(showNotification({ message: response.message, status: 1 }));
        console.log(response);
        setNewPassword({
          emailId: "",
          password: "",
        });
        navigate("/Login");
      } else {
        // ShowToastErrorMsg(
        //   response.message || response.error.response.data.message
        // );
        dispatch(showNotification({ message: response.message, status: 0 }));
      }
    } else {
      console.log("Error while create NewPassword");
    }
  };
  return (
    <>
      <div className="admin-login-page">
        <div className="form-container">
          <div className="dash-logo-bg text-start ps-3">
            <img className="dashboard-logo-img py-2" src={""} alt="" />
          </div>

          <form className="login-form">
            <div className="mb-4">
              <h4 className="mt-4 font-bold">Change Password</h4>
            </div>

            <div className="mb-3">
              <div className={`form-control w-full`}>
                <label className="label">
                  <span className={"label-text text-base-content "}>
                    Enter New Password
                  </span>
                </label>
                <div className="relative">
                  <input
                    type={password ? "text" : "password"}
                    id="password"
                    className="input input-bordered w-full"
                    placeholder="******"
                    aria-invalid={Boolean(
                      NewPasswordValidation?.password?.status === false
                    )}
                    defaultValue={NewPassword?.password || ""}
                    onChange={(e) => {
                      setNewPassword({
                        ...NewPassword,
                        password: e.target.value,
                      });
                      setpasswordStrength(e.target.value);
                    }}
                    onBlur={(e) => {
                      setNewPasswordValidationValue(
                        "password",
                        PasswordValidation(e.target.value)
                      );
                    }}
                    autoComplete="off"
                  />

                  <div className="psw-icon">
                    {password ? (
                      <span className="" onClick={() => viewPassword()}>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                          <path
                            fillRule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                            clipRule="evenodd"
                          />
                        </svg> */}
                        <EyeIcon className="h-6 w-6" />
                      </span>
                    ) : (
                      <span className="" onClick={() => viewPassword()}>
                        <EyeSlashIcon className="h-6 w-6" />
                      </span>
                    )}
                  </div>
                  <small className="text-error">
                    {NewPasswordValidation?.password?.message
                      ? ` Password ${NewPasswordValidation?.password?.message}`
                      : ""}
                  </small>
                </div>
              </div>

              {passwordStrength ? (
                <div className="mt-3">
                  <b>Create a password with the following</b>
                  <br />
                  {
                    <p
                      className={`${
                        passwordStrength.trim().length < 8
                          ? "text-error"
                          : "text-success"
                      } d-flex`}
                    >
                      {passwordStrength.trim().length < 8 ? (
                        <XCircleIcon className="h-6 w-6 inline mr-2" />
                      ) : (
                        <CheckCircleIcon className="h-6 w-6 inline mr-2" />
                      )}
                      At least 8 Characters
                    </p>
                  }
                  {
                    <p
                      className={`${
                        !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(passwordStrength)
                          ? "text-error"
                          : "text-success"
                      } d-flex`}
                    >
                      {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(passwordStrength) ? (
                        <XCircleIcon className="h-6 w-6 inline mr-2" />
                      ) : (
                        <CheckCircleIcon className="h-6 w-6 inline mr-2" />
                      )}
                      An Uppercase &amp; Lowercase character
                    </p>
                  }
                  {
                    <p
                      className={`${
                        !/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                          passwordStrength
                        )
                          ? "text-error"
                          : "text-success"
                      } d-flex`}
                    >
                      {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                        passwordStrength
                      ) ? (
                        <XCircleIcon className="h-6 w-6 inline mr-2" />
                      ) : (
                        <CheckCircleIcon className="h-6 w-6 inline mr-2" />
                      )}
                      A special character
                    </p>
                  }
                  {
                    <p
                      className={`${
                        !/[0-9]/.test(passwordStrength)
                          ? "text-error"
                          : "text-success"
                      } d-flex`}
                    >
                      {!/[0-9]/.test(passwordStrength) ? (
                        <XCircleIcon className="h-6 w-6 inline mr-2" />
                      ) : (
                        <CheckCircleIcon className="h-6 w-6 inline mr-2" />
                      )}
                      A number
                    </p>
                  }
                </div>
              ) : null}
            </div>
            <div className="mb-3 ">
              <div className={`form-control w-full`}>
                <label className="label">
                  <span className={"label-text text-base-content "}>
                    Re-Enter Password
                  </span>
                </label>
                <div className="relative">
                  <input
                    type={password1 ? "text" : "password"}
                    id="password"
                    className="input input-bordered w-full"
                    placeholder="******"
                    aria-invalid={Boolean(
                      NewPasswordValidation?.re_password?.status === false
                    )}
                    onChange={(e) => {
                      setNewPassword({
                        ...NewPassword,
                        re_password: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      setNewPasswordValidationValue(
                        "re_password",
                        PasswordValidation(
                          e.target.value,
                          NewPassword?.password
                        )
                      );
                    }}
                    autoComplete="off"
                  />

                  <div className="psw-icon">
                    {password1 ? (
                      <span className="" onClick={() => viewPassword1()}>
                        <EyeIcon className="h-6 w-6" />
                      </span>
                    ) : (
                      <span className="" onClick={() => viewPassword1()}>
                        <EyeSlashIcon className="h-6 w-6" />
                      </span>
                    )}
                  </div>
                  <small className="text-error">
                    {NewPasswordValidation?.password?.message
                      ? ` Password ${NewPasswordValidation?.password?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            </div>

            {errorMessage && (
              <ErrorText styleClass="mt-4">{errorMessage}</ErrorText>
            )}

            <div className="my-4">
              <button
                type="submit"
                className="mb-3"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
