// * Redux imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";

// * Redux Slices
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import loginSlice from "../features/user/loginSlice";

// * Redux Persistent Storage
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";

const persistConfig = {
  key: "engineerspg",
  storage,
};

const rootReducer = combineReducers({
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  login: loginSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
