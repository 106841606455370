import React, { lazy, useEffect, useState } from "react";
import "react-notifications/lib/notifications.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";
import Otp from "./features/user/Otp";
import { SetPassword } from "./features/user/SetPassword";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { removeNotificationMessage } from "./features/common/headerSlice";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const LandingLayout = lazy(() => import("./containers/LandingPage/Layout"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();
console.log(token)
function App() {
  const dispatch = useDispatch();
  const { newNotificationMessage, newNotificationStatus } = useSelector(
    (state) => state.header
  );

  useEffect(() => {
    if (newNotificationMessage !== "") {
      if (newNotificationStatus === 1)
        NotificationManager.success(newNotificationMessage, "Success", 2000);
      if (newNotificationStatus === 0)
        NotificationManager.error(newNotificationMessage, "Error", 3000);
      dispatch(removeNotificationMessage());
    }

  }, [dispatch, newNotificationMessage, newNotificationStatus]);
  useEffect(() => {
    
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);
  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   if (pathname.includes('/Login')) {

  //     window.location.reload();
  //   }
  // }, []);

  return (
    <>
      <NotificationContainer />
      <Router>
        <Routes>
        <Route
            path="/Login"
            element={
              !token ? (
                <Login />
              ) : (
                <Navigate to="/app/dashboard" replace />
              )
            }
          />
           
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verifyotp" element={<Otp />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/register" element={<Register />} />

          {/* Place new routes over this */}
          <Route path="/*" element={<LandingLayout />} />
          <Route path="/app/*"  
          
          
          element={
            token ? (
              <Layout />
            ) : (
             
              <Login />
            )
          } />

       
        </Routes>
      </Router>
    </>
  );
}

export default App;
