import axios from "axios";
import { AdminLogOut } from "../api/commonApi";

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 

    for all internal dashboard routes  */

  const TOKEN = localStorage.getItem("token");

  // const USERID = localStorage.getItem("userId");

  const WEBSITE_ROUTES = [
    "",

    "about",

    "branches",

    "gallery",

    "testimonials",

    "investment",

    "contact",
  ];

  const PUBLIC_ROUTES = [
    "login",

    "forgot-password",

    "register",

    "documentation",

    "verifyotp",

    "set-password",

    ...WEBSITE_ROUTES,
  ];

  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  const logoutUser = async () => {
    const USERID = localStorage.getItem("userId");

    const res = await AdminLogOut({ userId: USERID });

    if (res.statusCode === 200) {
      localStorage.clear();

      window.location.href = "/";
    }
  };

  if (!TOKEN && !isPublicPage) {
    window.location.href = "/Login";

    return;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(
      function (config) {
        // UPDATE: Add this code to show global loading indicator

        document.body.classList.add("loading-indicator");

        return config;
      },

      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // UPDATE: Add this code to hide global loading indicator

        document.body.classList.remove("loading-indicator");

        return response;
      },

      function (error) {
        // console.log(error);

        if (error?.response?.status === 401) {
          console.log("TOKEN Expired: " + error.response);

          logoutUser();

          return;
        }

        document.body.classList.remove("loading-indicator");

        return Promise.reject(error);
      }
    );

    return TOKEN;
  }
};

export default checkAuth;
