import axios from "axios";
import { url } from "./baseUrl";

const APIBaseUrl = url + "/EngineersPG/v1";

// NON Login Api Call (Landing Page)

export const GetAllPGListingWithoutToken = async () => {
  return await axios
    .get(`${APIBaseUrl}/nonlogin/pgmanagement/listing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
};

export const GetAllLinks = async () => {
  return await axios
    .get(`${APIBaseUrl}/User/CMS/findParticular?pageName=footer`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
};

export const contactus = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/enquiryform/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const getTestimonials = async () => {
  const response = await axios
    .get(`${APIBaseUrl}/User/testimonial/find?status=Approved`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const getPageCms = async (name) => {
  const response = await axios
    .get(`${APIBaseUrl}/User/CMS/findParticular?pageName=${name}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const FindPrivacyPolicyTermsCondition = async (cmsDocumentType) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/nonUser/cmsDocument/findParticular?cmsDocumentType=${cmsDocumentType}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const getMealsByDay = async (pgId, day) => {
  const response = await axios
    .get(`${APIBaseUrl}/nonlogin/meal/listing?pgId=${pgId}&day=${day}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const getPgById = async (pgId, day) => {
  const response = await axios
    .get(`${APIBaseUrl}/nonlogin/pgmanagement/findParticular?pgId=${pgId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const getMetaByPath = async (pathname) => {
  // console.log(pathname, "112");
  const response = await axios
    .get(`${APIBaseUrl}/nonUser/Metatag/findParticular?pageName=${pathname}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const AdminLogin = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/login`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const AdminLogOut = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/logOut`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

//

export const GetAdminNotifications = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/appNotification/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateNotificationStatus = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/appNotification/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteAllNotification = async (Payload, token) => {
  console.log(Payload);
  const response = await axios
    .delete(`${APIBaseUrl}/appNotification/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetEmployeeListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/employee/listing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAdminListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Admin/employee/listing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetEmployeeCustomeNotification = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Employee/customNotification/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetCustomeNotification = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Employee/customNotification/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetSAASOwner = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/saas/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * Admin Dashboard Count GET API

export const GetAllDashboardCount = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/dashboard/count`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllDashboardIncomeExpense = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/dashboard/revenueGraph`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * add

export const DeleteInventoryType = async (Payload, token) => {
  console.log(Payload);
  const response = await axios
    .delete(`${APIBaseUrl}/masterdata/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// export const GetInventoryList = async (token) => {
//   const response = await axios
//     .get(`${APIBaseUrl}/inventory/find?start=0&end=10`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ err: error }));
//   return response;
//}

// * Income and Expense
export const IncomeExpenseCreate = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/incomeexpense/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
export const IncomeExpenseDelete = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/incomeexpense/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * lead

export const GetLeadList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/lead/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetLeadComments = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/leadComments/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreatePassword = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/forgotPassword`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/verifyOTP`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateNewPassword = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/setNewPassWord`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const RoleManagementOwned= async (token, status,ownerId) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeerole/listing?status=${status}&userType=Employee&ownerId=${ownerId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const RoleManagement = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeerole/listing?status=${status}&userType=Employee`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const AdminRoleManagement = async (token, status, userType) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeerole/listing?status=${status}&userType=${userType}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const CustomeRoleMangement = async (token, status) => {
  const response = await axios
    .get(
      `${APIBaseUrl}customNotification/find?createdBy=650ee23e1dc99e97ac0a1034${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateEmployeeRole = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/employeerole/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const RoleDelete = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/employeerole/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const RoleDetails = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeerole/findparticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateRole = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/employeerole/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMasterDataDesignation = async (token, type) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/listing?type=${type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateMasterDataDesignation = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/masterdata/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateEmployee = async (payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/create`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteEmployee = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/employee/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const EmployeeDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/employee/findparticular?userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UserDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/user/findParticular?userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateUser = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/user/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const UpdateEmployee = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/employee/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateTenant = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/tenantProfile/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UserPasswordUpdate = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/user/changepassword`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Craete Leave Master Type

export const CreateLeaveMaster = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/masterdata/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get all Leave master

export const GetAllLeaveMaster = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/listing?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllLeaveMasterWithoutStatus = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/listing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Leave Master Specific

export const LeaveMasterDetails = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/findparticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Leave Master

export const UpdateLeaveMaster = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/masterdata/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const LeaveMasterDelete = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/masterdata/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get all Leave

export const GetAllLeave = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/leave/find?user=admin&pgId=all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ erroror: error }));
  return response;
};

//Craete Leave

export const CreateLeave = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/leave/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const CreateAdminLeave = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/admin/leave/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get all PG

export const GetAllPG = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/pgmanagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Leave Specific

export const LeaveDetailSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/leave/findParticular?leave_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Leave

export const UpdateLeave = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/leave/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Get all notice

export const GetAllNotice = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeeNotice/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllNoticeWithStatus = async (token, status, id) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/employeeNotice/find?status=${status}&employeeId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Craete employee notice

export const CreateEmployeeNotice = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/employeeNotice/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Notice Specific

export const NoticeDetailSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeeNotice/findParticular?id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Notice

export const UpdateNotice = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/employeeNotice/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetMasterDataWithStatus = async (token, status) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/listing?status=${status}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateMasterData = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/masterdata/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetByType = async (params, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/masterdata/listing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * add
export const CreateByType = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/masterdata/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
// export const DeleteInventoryType = async (Payload, token) => {
//   console.log(Payload);
//   const response = await axios
//     .delete(`${APIBaseUrl}/masterdata/delete`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//       data: Payload,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ err: error }));
//   return response;
// };

// * edit
export const EditMasterDataValue = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/masterdata/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetPG = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/pgmanagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Inventory List
export const CreateInventoryList = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/inventory/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetInventoryList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/inventory/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const UpdateInventoryList = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/inventory/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
export const DeleteInventoryList = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/inventory/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const IncomeExpenseUpdate = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/incomeexpense/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Specific CMS
export const GetSpecificCMS = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Admin/CMS/findParticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Specific CMS Update
export const UpdateSpecificCMS = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/Admin/CMS/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * Banner List
export const GetAllBannerList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Banner/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * FAQ List
export const GetAllFAQList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/FAQ/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Create FAQ List

// * Income and Expense
export const CreateFAQ = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/FAQ/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Delete FAQ List

export const DeleteFAQ = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/FAQ/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Update FAQ List

export const UpdateFAQ = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/FAQ/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Testimonials List
export const GetAllTestimonalsList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Admin/testimonial/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Testimonial Status Update

export const UpdateTestimonials = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/Admin/testimonial/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * Income and Expense
export const IncomeExpenseFind = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/incomeexpense/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * lead

// * Inventory List
export const CreateLeadList = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/lead/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Inventory List
export const UpdateLeadList = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/lead/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const DeleteLeadList = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/lead/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const LeadFindOne = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/lead/findParticular?lead_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// * meal

export const MealCreate = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/meal/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const MealFind = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/meal/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const MealUpdate = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/meal/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const MealDelete = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/meal/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * room
export const FindRoom = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomManagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreateRoom = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/roomManagement/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const UpdateRoom = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/roomManagement/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const RoomDelete = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/roomManagement/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * attendance api list

export const FindAttendance = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/attendance/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetRoomCleaningList = async (params, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomcleaning/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreateRoomCleaningList = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/roomcleaning/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const UpdateRoomCleaningList = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/roomcleaning/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const DeleteRoomCleaningList = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/roomcleaning/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const RoomCleaningDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomcleaning/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UploadDocument = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/awsUpload/upload`, Payload, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UploadMultipleImages = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/awsUpload/multiFileUpload`, Payload, {
      headers: {
        "Content-Type": "multipart/form-data;",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllPGListing = async (token,owned,ownerId,params) => {
  const response = await axios
    .get(`${APIBaseUrl}/pgmanagement/find?owned=${owned}&ownerId=${ownerId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      // 
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllEnquiries = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/enquiryform/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateEnquires = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/enquiryform/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetTenantListing = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantProfile/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteTenant = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/tenantProfile/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificTenantListing = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantProfile/findParticular?userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSosSpecificEmployee = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/sos/adminList`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPollResponseListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/PollResponse/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPollVisitsListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Pollvisit/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPaymentListing = async (token, userId) => {
  const response = await axios
    .get(`${APIBaseUrl}/paymentHistory/find?tenantId=${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllPaymentListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/paymentHistory/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificPGDetails = async (params, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/pgmanagement/findparticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Get all cms

export const GetAllCMS = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/cmsDocument/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//cms Specific

export const CMSDetailSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/cmsDocument/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create cms

export const CreateCMS = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/cmsDocument/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// update cms
export const UpdateCMS = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/cmsDocument/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Get all compliant management

export const GetAllCM = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/complaintManagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//compliant management Specific

export const CMDetailSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/complaintManagement/findParticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// update compliant management
export const UpdateCM = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/complaintManagement/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Get all comment

export const GetAllComment = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/complaintComments/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// create comment
export const CreateEmployeeNoticeComment = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/employeeNoticeComments/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const CreateComment = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/complaintComments/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// * Wastefood find

export const GetWasteFood = async (params, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/wastefood/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreatePG = async (payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/pgmanagement/create`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const PGDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/pgmanagement/findparticular?_id=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeletePG = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/pgmanagement/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdatePg = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/pgmanagement/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * Tanent
// * Payment History

export const GetPaymentList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/paymentHistory/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetRomeCleaningList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomcleaning/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// export const GetFoodList = async (token, params) => {
//   const response = await axios
//     .get(`${APIBaseUrl}/tenantfood/find`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       params,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ error: error }));
//   return response;
// };

export const GetFoodList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantfood/tenantFoodListing`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTenantComplaintList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/complaintManagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetCommentsComplaintList = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/complaintComments/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetCommentsForEmployeeNotice = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/employeeNoticeComments/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetCommentsForRoomNotice = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/RoomComments/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificTenantDetails = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantProfile/findParticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetSpecificTenantDocuments = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Employee/profileDocuments/findParticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTenantNotice = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/RoomNotice/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetNotice = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/RoomNotice/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificPollDetails = async (params, token) => {
  console.log(params);
  const response = await axios
    .get(`${APIBaseUrl}/Poll/findParticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetInOutlist = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/accesstenant/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetPollListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/Poll/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// * create notice comment
export const CreateNoticeComments = async (payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/RoomComments/create`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTenantFood = async (payload, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantfood/find`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const PollCreate = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/Poll/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const PollDelete = async (Payload, token) => {
  const response = await axios
    .delete(`${APIBaseUrl}/Poll/delete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: Payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const PollUpdate = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/Poll/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

export const GetRoomInventory = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomInventory/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateRoomInventory = async (token, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/roomInventory/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateRoomInventory = async (token, Payload) => {
  const response = await axios
    .put(`${APIBaseUrl}/roomInventory/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetRoomCleaning = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/roomcleaning/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetTenantProfile = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/tenantProfile/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificEmployeeDetails = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/employee/findparticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetEmployeeLeaveListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/leave/find?user=admin&status=all&pgId=all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetEmployeeAttendance = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/attendance/employeeBasedAttendance`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetParticularEmployeeAttendance = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/attendance/particularEmployeeAttendance`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetParticularDateAttendance = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/attendance/particularDateAttendance`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//SAAS Owners

export const GetAllOwnersListing = async (token, params) => {
  const response = await axios
    .get(`${APIBaseUrl}/saas/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateOwners = async (payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/saas/create`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateOwners = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/saas/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const OwnerDetailsSpecific = async (token, id) => {
  const response = await axios
    .get(`${APIBaseUrl}/saas/findParticular?userId=${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const DeleteOwners = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/saas/delete`,
      {
        data: Payload,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetSpecificOwnerDetails = async (params, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/saas/findParticular`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Banner Create

// * add
export const CreateBanner = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/Banner/create`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// Update Banner

export const UdpateBanner = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/Banner/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};

// *Booking Block API
export const GetAllBookingBlock = async (token, user, pgId) => {
  const response = await axios
    .get(`${APIBaseUrl}/bookingblock/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        user: user,
        pgId: pgId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAllBookingBlock1 = async (token, user) => {
  const response = await axios
    .get(`${APIBaseUrl}/bookingblock/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        // status:status,
        user: user,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// WIFI MANAGEMENT
export const GetAllWifiManagement = async (token, pgId) => {
  const response = await axios
    .get(`${APIBaseUrl}/wifimanagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        pgId: pgId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const GetAllWifiManagement2 = async (token, createdBy) => {
  const response = await axios
    .get(`${APIBaseUrl}/wifimanagement/find`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        createdBy: createdBy,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const GetAllScheduleVisit = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/schedulevisit/find?user=admin&status=all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdateScheduleVisit = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/schedulevisit/update`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ err: error }));
  return response;
};
