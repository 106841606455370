import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    title: "", // current  title state management
    isOpen: false, // modal state management for opening closing
    bodyType: "", // modal content management
    size: "", // modal content management
    extraObject: {},
    callBack: () => {},
    id: "",
  },
  reducers: {
    openModal: (state, action) => {
      const { title, bodyType, extraObject, size, callBack, id } =
        action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.title = title;
      state.size = size || "lg";
      state.extraObject = extraObject;
      state.callBack = callBack || (() => {});
      state.id = id;
    },

    closeModal: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.title = "";
      state.extraObject = {};
    },

    updateExtraObject: (state, action) => {
      console.log(action.payload);
      state.extraObject = action.payload;
    },
  },
});

export const { openModal, closeModal, updateExtraObject } = modalSlice.actions;

export default modalSlice.reducer;
