import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
    token: "",
    name: "",
    userType: "",
    userId: "",
    emailId: "",
    profileImage: "",
    roleRestriction: []
  },
  reducers: {
    loginSuccess: (state, action) => {
      const userDetails = action.payload;
      state.isLoggedIn = true;
      state.name = userDetails?.name;
      state.userType = userDetails?.userType;
      state.token = userDetails?.token?.accessToken;
      state.userId = userDetails?.userId;
      state.emailId = userDetails?.emailId;
      state.profileImage = userDetails?.profileImage;
      state.roleRestriction = userDetails?.role
    },
    logoutSuccess: (state, action) => {
      state.isLoggedIn = false;
      state.name = "";
      state.userType = "";
      state.token = "";
      state.userId = "";
      state.emailId = "";
      state.roleRestriction = []
    },
    loginStateUpadte: (state, action) => {
      const userDetails = action.payload;
      state.name = userDetails?.name;
      state.profileImage = userDetails?.profileImage;
    },
  },
});

export const { loginSuccess, logoutSuccess, loginStateUpadte } =
  loginSlice.actions;

export default loginSlice.reducer;
