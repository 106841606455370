// * All Regex patterns
export const EmailRegex = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const URLRegex = !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
export const GSTNumberRegex =
  !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const PhoneRegex = !/^[0-9]+$/;
export const PhoneWithoutStartZeroRegex = !/^[1-9][0-9]{9}$/;
export const NumberRegex = /^[0-9]/;
export const NumberWithoutZeroRegex = /^[1-9][0-9]*$/;
export const StringRegex = /^[A-Za-z ]+$/;

// Validations Functions

export function EmailValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: " is Required." };
  } 
  else
   if (value?.includes(" ")) {
    return { status: false, message: "cannot include spaces." };
  } 
  if(!value){
    
  }
  else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return { status: false, message: "is invalid" };
  }
  return { status: true, message: "" };
}

// export function EmailValidation(value) {
//   if (!value?.trim()?.length) {
//     return { status: false, message: " is Required." };
//   } 
//   else if (value?.includes(" ")) {
//     return { status: false, message: "cannot include spaces." };
//   } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
//     return { status: false, message: "is invalid" };
//   }
//   return { status: true, message: "" };
// }

export function URLValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: " is Required." };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot include spaces." };
  } else if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
    return { status: false, message: "is invalid" };
  }
  return { status: true, message: "" };
}

export function GSTNumberValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: " is Required." };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot include spaces." };
  } else if (GSTNumberRegex.test(value)) {
    return { status: false, message: "is invalid" };
  }
  return { status: true, message: "" };
}

export function PasswordValidation(value) {
  if (!value?.length) {
    return { status: false, message: " is Required" };
  } else if (value?.length > 30) {
    return {
      status: false,
      message: "must allowed maximum 30 characters only",
    };
  }
  const password = [
    /[a-z]/,
    /[A-Z]/,
    /[0-9]/,
    /[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]/,
  ].every((pattern) => pattern.test(value));
  return !password
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}
export function StringValidation(value) {
  if (!value || !value?.length) {
    return { status: false, message: " is Required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  } else if (value === undefined) {
    return { status: false, message: " is Required" };
  } else if (value === "") {
    return { status: false, message: " is Required" };
  } else if (value === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (value.length <= 1) {
    return { status: false, message: "must be maximum 2 letters" };
  }

  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}

export function NonEmptyValidation(value) {
  console.log(value?.length)
  if (!value?.length) {
    return { status: false, message: " is Required" };
  } else if (value === null) {
    return { status: false, message: " is Required" };
  } else if (value === undefined) {
    return { status: false, message: " is Required" };
  } else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}


export function MerchantIDValidation(merchantID) {
  if (!merchantID) {
    return { status: false, message: "Merchant ID cannot be empty" };
  } else if (!/^[A-Za-z0-9]+$/.test(merchantID)) {
    return { status: false, message: "Invalid Merchant ID format" };
  } else if (merchantID.toString().length === 0) {
    return { status: false, message: "Merchant ID is required" };
  } else if (merchantID.toString().includes(" ")) {
    return { status: false, message: "Merchant ID cannot include spaces" };
  }

  return { status: true, message: "" };
}







export function ToggleValiadtion(value) {
  if (typeof value === "boolean") {
    return { status: true, message: "" };
  }
}

export function PhoneNumberValidation(value) {
  if (value === undefined || value === null || value.toString().trim() === "") {
    return { status: false, message: " is required" };
  }

  const phoneNumber = value.toString();
  if (!/^[6-9][0-9]{9}$/.test(phoneNumber)) {
    if (!/^[0-9]+$/.test(phoneNumber)) {
      return { status: false, message: " must contain only numeric digits" };
    } else if (phoneNumber.length !== 10) {
      return { status: false, message: " must be 10 digits" };
    } else if (!/^[6-9]/.test(phoneNumber)) {
      return { status: false, message: " is Invalid" };
    } else {
      return { status: false, message: " must not start with 0" };
    }
  }

  return { status: true, message: "" };
}


export function NumberValidation(value) {
  if (!value?.toString().length) {
    return { status: false, message: " is Required" };
  } else if (value?.toString().includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function AgeValidation(value) {
  if (!value?.toString().length) {
    return { status: false, message: " is Required" };
  } else if (value?.toString().includes(" ")) {
    return { status: false, message: "cannot include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be less than Zero" };
  } else if (value < 1 || value > 17) {
    return { status: false, message: "must be between 1 and 17" };
  }

  return !NumberRegex.test(value)
    ? { status: false, message: "is not a valid number" }
    : { status: true, message: "" };
}

export function AmountValidation(value) {
  console.log(value);
  if (value === undefined) {
    console.log(1);
    return { status: false, message: "is Required" };
  } else if (!value.toString()?.length) {
    console.log(2);
    return { status: false, message: "is Required" };
  } else if (value === "0") {
    return { status: false, message: "Amount should not be zero" };
  }

  const amountRegex = /^[0-9]+(\.[0-9]{1,2})?$/;
  const numericValue = parseFloat(value);

  if (numericValue < 100) {
    return { status: false, message: " should be above 100" };
  }

  return !amountRegex.test(value)
    ? { status: false, message: " should be a valid amount" }
    : { status: true, message: "" };
}

export function lastNameValidation(value) {
  if (!value?.length) {
    return { status: false, message: " is Required" };
  } else if (typeof value !== "string") {
    return { status: false, message: "must be a alphabets" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  }

  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}
// export function DateValidation(value) {
//   // var todayDate = new Date();
//   // var month = todayDate.getMonth() + 1;
//   // var year = todayDate.getUTCFullYear();
//   // var tdate = todayDate.getDate();
//   // if (month < 10) {
//   //   month = "0" + month;
//   // }
//   // if (tdate < 10) {
//   //   tdate = "0" + tdate;
//   // }
//   // var minDate = year + "-" + month + "-" + tdate;
//   if (!value?.length) {
//     return { status: false, message: "is required" };
//   } else if (value?.includes(" ")) {
//     return { status: false, message: "cannot be include space" };
//   } else if (value?.length <= 3) {
//     return { status: false, message: "not a valid year" };
//   }
//   // else if (!NumberRegex.test(value)) {
//   //   return { status: false, message: "is only a number" };
//   // }
//   return NumberRegex.test(value)
//     ? { status: true, message: "" }
//     : { status: false, message: "is only a number" };
// }

export function DateValidation(value) {
  var todayDate = new Date();
  var month = todayDate.getMonth() + 1;
  var year = todayDate.getUTCFullYear();
  var tdate = todayDate.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (tdate < 10) {
    tdate = "0" + tdate;
  }
  var minDate = year + "-" + month + "-" + tdate;
  if (!value?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (value?.length <= 3) {
    return { status: false, message: "not a valid year" };
  } else if (!NumberRegex.test(value)) {
    return { status: false, message: "is only a number" };
  }
  return minDate >= value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be future date" };
}

export function DOBValidation(value) {
  console.log(value);

  if (!value?.length) {
    return { status: false, message: "Date of Birth is required" };
  }

  const todayDate = new Date();
  const inputDate = new Date(value);

  if (isNaN(inputDate.getTime())) {
    return { status: false, message: "Not a valid date format" };
  }

  const currentYear = todayDate.getFullYear();
  const currentMonth = todayDate.getMonth() + 1;
  const currentDate = todayDate.getDate();

  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth() + 1;
  const inputDay = inputDate.getDate();

  if (
    value.length !== 10 ||
    value.charAt(4) !== "-" ||
    value.charAt(7) !== "-"
  ) {
    return {
      status: false,
      message: "Invalid date format. Please use YYYY-MM-DD",
    };
  }

  if (inputYear < currentYear - 18) {
    return { status: true, message: "" };
  }

  if (inputYear === currentYear - 18) {
    if (inputMonth < currentMonth) {
      return { status: true, message: "" };
    }
    if (inputMonth === currentMonth && inputDay <= currentDate) {
      return { status: true, message: "" };
    }
  }

  return { status: false, message: "Must be at least 18 years old" };
}

export function AadharValidation(aadhar) {
  // Check if empty
  if (!aadhar?.toString()?.length) {
    return { status: false, message: "is required" };
  }

  // Remove spaces and convert to uppercase
  // aadhar = aadhar.replace(/\s/g, "").toUpperCase();

  // Check if the Aadhar number is exactly 12 digits long
  if (aadhar?.toString()?.length !== 12) {
    return { status: false, message: "must be 12 digits" };
  }

  // Check if all characters are digits
  if (!/^\d+$/.test(aadhar)) {
    return { status: false, message: "must be only digits" };
  }

  return { status: true, message: "" };
}

export function IndianLicenseNumberValidation(licenseNumber) {
  if (!licenseNumber?.length) {
    return { status: true, message: "" };
  }

  // Remove spaces and convert to uppercase
  licenseNumber = licenseNumber.replace(/\s/g, "").toUpperCase();

  // Check if the license number is alphanumeric and has a length of 15 characters
  if (!/^[A-Z0-9]{15}$/.test(licenseNumber)) {
    return { status: false, message: "is Invalid" };
  }

  // Check if the first two characters are letters (state code)
  const stateCode = licenseNumber.slice(0, 2);
  if (!/^[A-Z]+$/.test(stateCode)) {
    return { status: false, message: "is Invalid" };
  }

  // Check if the next two characters are digits (district code)
  const districtCode = licenseNumber.slice(2, 4);
  if (!/^\d{2}$/.test(districtCode)) {
    return { status: false, message: "is Invalid" };
  }

  // Check if the remaining characters are alphanumeric (unique identifier)
  const uniqueIdentifier = licenseNumber.slice(4);
  if (!/^[A-Z0-9]+$/.test(uniqueIdentifier)) {
    return { status: false, message: "is Invalid" };
  }

  return { status: true, message: "" };
}

export function PANValidation(pan) {
  // Check if empty
  if (!pan?.toString()?.length) {
    return { status: false, message: "PAN is required" };
  }

  // Remove spaces and convert to uppercase
  pan = pan.replace(/\s/g, "").toUpperCase();

  // Check if the PAN is in the valid format
  if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan)) {
    return { status: false, message: "Invalid PAN format" };
  }

  return { status: true, message: "" };
}
// Image upload validation
export function ImageUploadValidation(file) {
  if (!file) {
    return { status: false, message: "No file selected" };
  }
  // Allowed file types (you can modify/add more as needed)
  // const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  // // Check if the file type is allowed
  // if (!allowedTypes.includes(file.type)) {
  //   return { status: false, message: "Invalid file type. Allowed types: JPEG, PNG, GIF" };
  // }
  // // Max file size in bytes (you can adjust this value)
  // const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
  // // Check if the file size exceeds the limit
  // if (file.size > maxSizeInBytes) {
  //   return { status: false, message: "File size is too large. Maximum size allowed is 5MB" };
  // }
  // If you need to check image dimensions (optional)
  const img = new Image();
  img.src = URL.createObjectURL(file);
  return new Promise((resolve) => {
    img.onload = function () {
      const width = this.width;
      const height = this.height;
      // Example: Check if image width or height exceeds a certain limit
      if (width > 1000 || height > 1000) {
        resolve({ status: false, message: "Image dimensions should not exceed 1000x1000" });
      } else {
        resolve({ status: true, message: "" });
      }
    };
  });
}
//Video upload validation
export function VideoUploadValidation(file) {
  if (!file) {
    return { status: false, message: "No file selected" };
  }

  // Allowed video file types (you can modify/add more as needed)
  const allowedTypes = ["video/mp4", "video/mpeg", "video/quicktime"];

  // Check if the file type is allowed
  if (!allowedTypes.includes(file.type)) {
    return { status: false, message: "Invalid file type. Allowed types: MP4, MPEG, QuickTime" };
  }

  // Max file size in bytes (you can adjust this value)
  const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

  // Check if the file size exceeds the limit
  if (file.size > maxSizeInBytes) {
    return { status: false, message: "File size is too large. Maximum size allowed is 50MB" };
  }

  // If all checks pass, return a success status
  return { status: true, message: "" };
}

//for Radio Button validation
export function RadioValidation(value) {
  if (value === undefined || null) {
    return {
      status: false,
      message: "Please select an option ",
    };
  }
  return {
    status: true,
    message: "",
  };
}
