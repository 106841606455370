//------------------------------------ Default / NPM package imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
//------------------------------------ Util imports
import { NumberValidation } from "../../app/Validation";
import logo from "../../assets/images/logoPg.png";
//------------------------------------ API imports
import { CreateOtp } from "../../api/commonApi";
import { showNotification } from "../common/headerSlice";
import { NotificationManager } from "react-notifications";
import ErrorText from "../../components/Typography/ErrorText";

export default function Otp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const [otp, setOtp] = useState();
  const [eText, setEText] = useState();

  // const [otpVerify, setOtpVerify] = useState({});
  const [otpVerifyValidation, setOtpVerifyValidation] = useState({});

  const setOtpValue = (key, value) => {
    setOtp({ ...otp, [key]: value });
    if (otpVerifyValidation[key]) delete otpVerifyValidation[key];
  };

  const setOtpVerifyValidationValue = (key, value) => {
    setOtpVerifyValidation({ ...otpVerifyValidation, [key]: value });
  };

  const validateLogin = () => {
    if (state?.auth?.isLoggedIn) {
      navigate("/Dashboard");
    }
  };
  useEffect(() => {
    validateLogin();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.otp = NumberValidation(otp);
    setOtpVerifyValidation(validate);
    console.log(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        otpType: "forgotPassword",
        type: "MobileNumber",
        mobileNumber: localStorage.getItem("email"),
        otp: Number(otp),
      };
      const response = await CreateOtp(Payload);
      if (response && response.statusCode === 200) {
        // ShowToastSuccessMsg(response.message);
        dispatch(showNotification({ message: response.message, status: 1 }));
        console.log(response);
        setOtp({
          otp: "",
        });
        navigate("/set-password");
      } else {
        // ShowToastErrorMsg(
        //   response.message || response.error.response.data.message
        // );
        // NotificationManager.warning(response.message, 3000);
        setEText(response?.message);
        // dispatch(showNotification({ message: response.message, status: 0 }));
      }
    } else {
      // dispatch(showNotification({ message: "Invalid Otp", status: 0 }));
      console.log("Error while create Otp");
    }
  };

  return (
    <div className="admin-login-page">
      <div className="form-container">
        <div className="dash-logo-bg text-start pl-3">
          <img
            className="dashboard-logo-img py-2"
            src={logo}
            alt=""
            width="70px"
            height="70px"
          />
        </div>

        <form className="login-form">
          <div className="mb-4">
            <h4 className="mt-4 font-bold"> Verify your email ID</h4>
            <p className="small">Enter the code we just sent to</p>
          </div>

          <div className="mb-3">
            <div className="mb-4 psw-icon-section">
              <OtpInput
                className="otpInput"
                // value={code}
                // onChange={handleChange}
                numInputs={4}
                // separator={<span style={{ width: "8px" }}></span>}
                // isInputNum={true}
                value={otp}
                onChange={setOtp}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid #CFD3DB",
                  borderRadius: "8px",
                  width: "45px",
                  height: "45px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid black ",
                  outline: "none",
                }}
                onBlur={(e) => {
                  setOtpVerifyValidationValue(
                    "otp",
                    NumberValidation("otp", e.target.value)
                  );
                }}
                autoComplete="off"
              />
              <small className="text-error">
                {otpVerifyValidation?.otp?.message
                  ? ` Otp ${otpVerifyValidation?.otp?.message}`
                  : ""}
              </small>
            </div>
          </div>
          <ErrorText styleClass="mt-2">{eText}</ErrorText>
          <div className="my-4">
            <button
              type="submit"
              className="mb-3"
              onClick={(e) => handleSubmit(e)}
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
